/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
import theme from '../../styles/theme';


const ItemsBarWrapper = styled.div`
  background: ${theme.body.gray};
  border-radius: 20px;
  padding: 6px 0;

  button {
    background: none;
    border: none;
    outline: none;
    margin: 0;
    padding: 4px 16px;
    color: ${theme.body.light};
    white-space: nowrap;

    &.selected,
    &:hover {
      color: ${theme.hover.hoverBlue};
      cursor: pointer;
    }
  }
`;
ItemsBarWrapper.displayName = 'ItemsBarWrapper';

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default ItemsBarWrapper;
