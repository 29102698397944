/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React, {
  PureComponent,
} from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Styles
import ItemsBarWrapper from './index.style';


class ItemsBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedKey: '',
    };
  }

  handleClick = (e) => {
    const { onClick } = this.props;
    const {
      currentTarget: {
        dataset: {
          key,
        },
      },
    } = e;

    this.setState(prevState => ({
      selectedKey: prevState.selectedKey !== key ? key : '',
    }), () => {
      const { selectedKey } = this.state;
      onClick(selectedKey);
    });
  };

  getElements = () => {
    const {
      items,
    } = this.props;
    const {
      selectedKey,
    } = this.state;

    return items.map((item) => {
      let cssName;

      if (selectedKey === item.key) {
        cssName = 'selected';
      }

      return (
        <button
          type="button"
          key={item.key}
          data-key={item.key}
          className={cssName}
          onClick={this.handleClick}
        >
          {item.value}
        </button>
      );
    });
  };

  render() {
    const itemElements = this.getElements();
    return (
      <ItemsBarWrapper>
        {itemElements}
      </ItemsBarWrapper>
    );
  }
}

/*------------------------------------------------*/
/* PROPTYPES
/*------------------------------------------------*/
ItemsBar.defaultProps = {
  onClick: _noop,
};
ItemsBar.propTypes = {
  // Required props:
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.node.isRequired,
    })
  ).isRequired,
  // Optional props:
  onClick: PropTypes.func,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default ItemsBar;
