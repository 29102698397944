/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import theme from '../../styles/theme';


const Wrapper = styled.article`
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #E5E5E5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  color: inherit;
  height: 100%;
  max-width: 326px;
  min-width: 326px;
  padding: 0;
  position: relative;
  transition: all .25s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    transform: scale(1.005);
  }

  a {
    display: block;
    position: relative;
    z-index: 2;
  }

  header {
    background: #EFF1F4;
    padding: 16px;
    border-radius: 10px 10px 0 0;
    min-height: 220px;
    display: flex;
    align-items: center;
  }

  section {
    padding: 12px 16px 16px 16px;

    p {
      color: ${theme.body.light};
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      margin: 0;
    }
  }
`;

function Card({
  title, image, description, link
}) {
  const shortDescriptionIndex = description.indexOf(' ', 90);

  return (
    <Wrapper>
      <Link to={link}>
        <header>
          {image && (
            <GatsbyImage
              image={getImage(image)}
              alt={image.description}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          )}
        </header>
        {description && (
          <section>
            <h3>{title}</h3>
            <p>
              {(description.length >= 90 && shortDescriptionIndex >= 0)
                ? `${description.substring(0, shortDescriptionIndex)} [...]`
                : description}
            </p>
          </section>
        )}
      </Link>
    </Wrapper>
  );
}

Card.defaultProps = {
  description: '',
  image: {},
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.shape({
    gatsbyImageData: PropTypes.shape({
    }),
    description: PropTypes.string,
  }),
};

export default Card;
