/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  graphql,
} from 'gatsby';
import styled from 'styled-components';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import {
  HeroHeading,
  BodyTextBig,
  WhiteSection,
  SectionTitle,
} from '../components/Global';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ItemsBar from '../components/ItemsBar';
import ItemsBarWrapper from '../components/ItemsBar/index.style';
import Container from '../components/Container';
import SignUpModal from '../components/SignUpModal';
import { GridItem, Grid } from '../components/Grid';
import Card from '../components/Card';


const ContainerHero = styled(Container)`
  padding-right: 40px;
  padding-left: 40px;
`;
ContainerHero.displayName = 'ContainerHero';

const ContainerItems = styled.div`
  margin-top: 80px;
  margin-bottom: 40px;
`;
const HeroWrapper = styled.div`
  margin-top: 80px;
  text-align: center;

  p {
    max-width: 830px;
    width: 100%;
    margin: auto;
  }

  ${ItemsBarWrapper} {
    max-width: 1110px;
    display: flex;
    justify-content: center;
    margin: 48px auto 0 auto;
    flex-wrap: wrap;
  }
`;

const getUniqueSolutions = (templateItems) => {
  let solutions = [];
  let solution;
  templateItems.forEach(
    (templateItem) => {
      solution = _get(templateItem, 'solution.team', '');
      if (solution) {
        solutions = solutions.concat(solution);
      }
    }
  );
  solutions = _uniq(solutions);
  solutions = solutions.map(value => ({
    key: value,
    value,
  }));

  return solutions;
};

const Templates = (props) => {
  // Prop values:
  const { location } = props;
  const originalTemplateItems = _get(props, 'data.allContentfulTemplate.nodes', []);

  // State values:
  const [filteredTemplates, setFilteredTemplates] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  // Use Effect function:
  React.useEffect(() => {
    // Set all items:
    setFilteredTemplates(originalTemplateItems);

    // Set available categories:
    const availableCategories = getUniqueSolutions(originalTemplateItems);
    setCategories(availableCategories);
  }, []);


  const handleCategoryClick = React.useCallback((category) => {
    if (category) {
      const filteredItems = originalTemplateItems.filter(
        item => (_get(item, 'solution.team', '') === category)
      );
      setFilteredTemplates(filteredItems);
    }
    else {
      setFilteredTemplates(originalTemplateItems);
    }
  });


  return (
    <Layout location={location}>
      <SEO
        title="Templates | Workast - Templates Library"
        description="Unlock endless possibilities for your project with Workast's customizable template library,  designed to suit every industry, business, and team. Let your creativity soar!"
      />
      <HeroWrapper>
        <ContainerHero>
          <HeroHeading mb="10px">Templates Library</HeroHeading>
          <BodyTextBig>
            Unlock endless possibilities for your project with Workast&apos;s
            customizable template library, designed to suit
            every industry, business, and team. Let your creativity soar!
          </BodyTextBig>
        </ContainerHero>
        <ContainerHero>
          <ItemsBar
            items={categories}
            onClick={handleCategoryClick}
          />
        </ContainerHero>
      </HeroWrapper>
      <Container>
        <ContainerItems>
          <Grid gridGap="2rem 2rem" minCol="280px" maxCol="320px">
            {filteredTemplates.map(templateItem => (
              <GridItem key={_get(templateItem, 'slug', '')}>
                <Card
                  link={`/templates/${_get(templateItem, 'slug', '')}/`}
                  title={_get(templateItem, 'name', '')}
                  description={_get(templateItem, 'tagline', '')}
                  image={_get(templateItem, 'images.0', null)}
                />
              </GridItem>
            ))}
          </Grid>
        </ContainerItems>
      </Container>
      <WhiteSection className="centered-text">
        <Container>
          <SectionTitle mb="16px">
            Make teamwork a breeze
          </SectionTitle>
          <BodyTextBig maxWidth="620px" mb="35px">
            Begin your upcoming project on Workast
            effortlessly with these exceptional templates.
          </BodyTextBig>
          <SignUpModal buttonText="Get Started" />
        </Container>
      </WhiteSection>
    </Layout>
  );
};

Templates.propTypes = {
  data: PropTypes.shape({
    allContentfulTemplate: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          tagline: PropTypes.string.isRequired,
          solution: PropTypes.shape({
            team: PropTypes.string,
          }),
          images: PropTypes.arrayOf(
            PropTypes.shape({
              description: PropTypes.string,
              gatsbyImageData: PropTypes.shape({
              }),
            }),
          ),
        })
      ),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default Templates;

export const pageQuery = graphql`
  query {
    allContentfulTemplate {
      nodes {
        id
        name
        slug
        tagline
        solution {
          team
        }
        images {
          description
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;
